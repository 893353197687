import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Heading,
  Text,
  Input,
  Button,
  Container,
  SimpleGrid,
  Select,
  Box,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Marketing from '../img/two-birds/marketing.jpg'

import jobs from '../data/jobs.json';
import OurJourney from '../components/home/banner';

// Sample job data
const jobsData = jobs

const JobSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [location, setLocation] = useState('');
  const [contractType, setContractType] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const urlParams = new URLSearchParams(window.location.search)
  const search = urlParams.get('query')
  useEffect(() => {
    // Populate location options
    const uniqueLocations = [...new Set(jobsData.map((job) => job.location))];
    setLocations(uniqueLocations);

    // Populate contract type options
    const uniqueContractTypes = [
      ...new Set(jobsData.map((job) => job.contractType)),
    ];
    setContractTypes(uniqueContractTypes);

    // Initialize filtered jobs with all jobs
    setFilteredJobs(jobsData);
  }, []);

  const handleSearch = (query) => {
    let newFilteredJobs = jobsData;

    if (searchTerm) {
      newFilteredJobs = newFilteredJobs.filter((job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (location) {
      newFilteredJobs = newFilteredJobs.filter(
        (job) => job.location.toLowerCase() === location.toLowerCase()
      );
    }

    if (contractType) {
      newFilteredJobs = newFilteredJobs.filter(
        (job) => job.contractType.toLowerCase() === contractType.toLowerCase()
      );
    }

    if (typeof query === 'string') {
      newFilteredJobs = newFilteredJobs.filter((job) =>
        job.title.toLowerCase().includes(query.toLowerCase())
      );
    }

    setFilteredJobs(newFilteredJobs);
  };

  useEffect(() => {
    console.log(search)
    setSearchTerm(search)
    handleSearch(search)
    
  }, [window.location])

  const handleReset = () => {
    setSearchTerm('');
    setLocation('');
    setContractType('');
    setFilteredJobs(jobsData);
  };

  return (
    <>
          <OurJourney
        heading='Search for jobs at the North biggest brands.'
        body="Finding a job can be a challenge, allow us to do the heavy lifting"
        image={Marketing}
        imageAlt=''
      />
    <Container maxW="container.lg" py={12}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
        mb={8}
      >
        <Input
          placeholder="Search by Job Title"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          mb={{ base: 4, md: 0 }}
          mr={{ base: 0, md: 4 }}
        />
        <Select
          placeholder="Location"
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            handleSearch();
          }}
          mb={{ base: 4, md: 0 }}
          mr={{ base: 0, md: 4 }}
        >
          {locations.map((loc) => (
            <option key={loc} value={loc}>
              {loc}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Contract Type"
          value={contractType}
          onChange={(e) => {
            setContractType(e.target.value);
            handleSearch();
          }}
          mb={{ base: 4, md: 0 }}
        >
          {contractTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <Button ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }} onClick={handleSearch}>
          Search
        </Button>
        <Button ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }} onClick={handleReset}>
          Reset
        </Button>
      </Flex>
      {filteredJobs.length === 0 ? (
        <Text textAlign="center" mb={4}>
          Sorry, no results for this search.
        </Text>
      ) : (
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={8}>
          {filteredJobs.map((job) => (
            <Box key={job.id} boxShadow="md" borderRadius="md" p={6}>
              <Heading fontSize="xl" mb={2}>
                {job.title}
              </Heading>
              <Text fontSize="md" fontWeight="bold" mb={2}>
                Location: {job.location}
              </Text>
              <Text fontSize="md" mb={2}>
                Salary: {job.salary}
              </Text>
              <Text fontSize="md" mb={4}>
                Contract Type: {job.contractType}
              </Text>
              <Text fontSize="md" mb={4}>
                {job.description.substring(0, 100) + '...'}
              </Text>
              <Link
                as={RouterLink}
                to={`/job/${job.id}/${encodeURIComponent(job.title)}`}
                color="blue.500"
              >
                View Details
              </Link>
            </Box>
          ))}
        </SimpleGrid>
      )}
    </Container>
          </>
  );
};

JobSearch.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      salary: PropTypes.string.isRequired,
      contractType: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default JobSearch;
