import React  from 'react';
import { NavLink, Link } from "react-router-dom";
import { useState } from "react";

import Logo from '../img/two-birds/two_birds_one_stone_logo_blue_TRANSPARENT.png';
import Menu from '../img/hamburger-menu.png';

import navigationData from '../data/header.json';

import '../scss/components/header.scss';
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const capitaliseFirstLetter = (string) => {
    let formattedSting = string.charAt(0).toUpperCase() + string.slice(1);
    return formattedSting.split('-').join(' ')
  }

  const handleToggleMenu = (desktop) => {
    if (!desktop) {
      setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    } 
  };

  return (
    <header className={`header ${isMenuOpen ? `header--open` : ''}`}>
      <div className="header__wrapper container">
        <img src={Menu} className="header__menu"alt="" onClick={() => handleToggleMenu()}></img>
        <Link className='header__strapline' to='/'><img src={Logo} className="header__logo" alt="Two Birds One Stone logo"></img><p>Your future, our focus</p>
</Link>
        <ul className="header__link-container">
          {
            navigationData.map((data, iterator) =>
            <li key={iterator} onClick={() => handleToggleMenu('desktop')} className='header__link'>
              <NavLink onClick={() => setIsMenuOpen(false)} isActive={(match, location) => {
                if (match) {
                  return true;
                } else if (location.pathname === '/' && data === 'home') {
                  return true;
                }
              }} to={`/${data}`}>{capitaliseFirstLetter(data)}</NavLink>
            </li> 
            )}
        </ul>
      </div>
    </header>
  );
}

export default Header;
