import React from 'react';

import OurJourney from '../components/home/banner';

import '../scss/components/instagram.scss';
import Marketing from '../img/two-birds/marketing.jpg'
import FeaturedJobs from '../components/featured-jobs';
import StepsComponent from '../components/steps';

import '../scss/pages/get-involved.scss';

function Home() {

  return (
    <div>
      <OurJourney
        heading='Our mission is to provide tailored support.'
        body="Your career journey is more than a placement for us; it's a partnership built on understanding, trust, and shared success."
        buttonLink='/about-us'
        buttonText='Find out more'
        image={Marketing}
        imageAlt=''
        home={true}
      />
          <StepsComponent />

      <FeaturedJobs/>      
    </div>
  );
}

export default Home;