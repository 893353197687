import React from 'react';

import Map from '../components/map';
import ContactForm from '../components/contact-form';

import '../scss/pages/get-involved.scss';

function GetInvolved() {
  return (
    <div>
      <Map/>
      <div className='container'>
        <ContactForm />
      </div>
    </div>
  );
}

export default GetInvolved;