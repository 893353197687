import React from 'react';
import { Box, Text, Flex, Square, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const steps = [
  {
    number: 1,
    title: 'Brief',
    description: 'We take time to understand your needs and requirements from a technical and cultural perspective whether candidate or client.'
  },
  {
    number: 2,
    title: 'Search',
    description: 'We have a diverse network of clients, allowing us to align candidates who meet clients requirements seamlessly'
  },
  {
    number: 3,
    title: 'Result',
    description: 'We ensure a smooth transition for the candidate into the new role. We offer support and guidance throughout the process and support you through you career journey.'
  }
];

const Step = ({ number, title, description, delay }) => {
  const MotionBox = motion(Box);

  return (
    <MotionBox
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5, delay }}
      p={5}
      textAlign="center"
      maxW="sm"
      m={3}
    >
      <Square size="50px" transform="rotate(45deg)" bg="#4ea1e9" color="white" mb={4} mx="auto">
        <Text transform="rotate(-45deg)"
fontSize="xl">{number}</Text>
      </Square>
      <Text fontSize="xl" fontWeight="bold" mb={2}>{title}</Text>
      <Text fontSize="md">{description}</Text>
    </MotionBox>
  );
};

Step.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
};

const StepsComponent = () => {
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <Box p={10} textAlign="center">
      <Text fontSize="3xl" fontWeight="bold" mb={10}>
        With You Every <Text as="span" color="#4ea1e9">Step</Text>.
      </Text>
      <Flex direction={flexDirection} justify="center" align="center">
        {steps.map((step, index) => (
          <Step key={step.number} {...step} delay={index * 0.3} />
        ))}
      </Flex>
    </Box>
  );
};

export default StepsComponent;
