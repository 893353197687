import React from 'react';

import Row from '../components/row-content';

import Sales from '../img/two-birds/sales.jpg';
import Salesman from '../img/two-birds/salesman.jpg';
import Laptop from '../img/two-birds/laptop.jpg';

import '../scss/pages/get-involved.scss';

function AboutUs() {
  return (
    <div>
      <Row
        image={Sales}
        imageAlt=""
        heading="About us"
        body="Help connect market leading professionals to pioneer businesses.


        Two Birds One Stone, it's in the name! We are specialist head-hunters to the FMCG and Real Estate Development industries. Committed to advancing candidates careers across the entire spectrum of Marketing & sales.
        
        
        We go beyond traditional recruitment. We aspire to give back to our clients by providing access to consultations with experienced life coaches to help you navigate both your professional and personal journey."
        styleModifier='get-involved'
      />
      <Row
        image={Salesman}
        imageAlt=""
        heading="Our Mission"
        body="Providing tailored support, We pride ourselves on truly listening to your requirements, fostering long-term relationships!



        We have a goal to become the most detailed, and supportive industry experts & career advisors in the North. Providing valuable market insight within the FMCG & Real estate development industries.
        
        
        
        
        Your success is our success, and your journey is our journey... We really do care."
        styleModifier='get-involved reverse'
      />
      <Row
        image={Laptop}
        imageAlt=""
        heading="Why choose us?"
        body="Your career journey is more than a placement for us; it's a partnership built on understanding, trust, and shared success.


        We support World-Class Enterprises across the country, and our industry knowledge expands over 20 years. Your success is not just a goal; it's our shared triumph, and every milestone in your journey is our mutual achievement. We want to support you reach the epitome of career excellence."
        styleModifier='get-involved'
      />
    </div>
  );
}

export default AboutUs;