import React  from 'react';
import PropTypes from 'prop-types';

import { Link } from "react-router-dom";

import '../../scss/components/our-journey.scss';

const OurJourney = ({
  image,
  imageAlt,
  heading,
  body,
  buttonLink,
  buttonText,
  home
}) => {
  const getSearchUrl = () => {
    const search = document.getElementById('query')
    return `/candidates?query=${search?.value}`
  };

  return (
    <section className="our-journey">
      <div className="our-journey__banner-div">
        <img className="our-journey__image" src={image} alt={imageAlt} />
      </div>
      <div className="our-journey__overlay"></div>
      <div className="our-journey__text-container container">
        <span className="banner-text">
          <h1>{heading}</h1>
          <p className="our-journey__text">{body}</p>
          { !home && buttonLink && <Link className="button" to={buttonLink}>{buttonText}</Link> }
          {home &&
            <div className="our-journey__search">
              <div className="our-journey__search--search-container">
                <label className="font-weight-bold text-secondary small" htmlFor="query">SEARCH BY KEYWORD</label>
                <input name="query" id="query" placeholder="Eg.Digital Marketing " type="text" aria-label="Eg. Digital Marketing"/>
              </div>
              <div className='our-journey__search--link'>
                <Link className="button" to={getSearchUrl}>Search Jobs</Link>
              </div>
            </div>}
        </span>
      </div>
    </section>
  );
}

OurJourney.propTypes = {
  image: PropTypes.function,
  imageAlt: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  home: PropTypes.bool
}

export default OurJourney;
