import React, { useState } from 'react';
import { Box, Container, FormControl, FormLabel, Input, Textarea, Button, Heading, Alert, AlertIcon } from '@chakra-ui/react';
import emailjs from '@emailjs/browser'; // Updated import

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    emailjs.sendForm(
      'two-birds',
      'template_wm7hp86',
      event.target,
      '87dw_w7T6iY2o4hjT'
    )
      .then((result) => {
        console.log(result.text);
        // Clear form fields
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        // Show success message
        setIsSuccess(true);
        // Hide success message after 5 seconds
        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  return (
    <Container maxW="lg">
      <Heading as="h1" mb={6}>Contact Us</Heading>
      <Box borderWidth="1px" p={6} borderRadius="md">
        {isSuccess && (
          <Alert status="success" mb={4}>
            <AlertIcon />
            Message sent successfully!
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl id="name" mb={4}>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" />
          </FormControl>
          <FormControl id="email" mb={4}>
            <FormLabel>Email address</FormLabel>
            <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" />
          </FormControl>
          <FormControl id="message" mb={4}>
            <FormLabel>Message</FormLabel>
            <Textarea name="message" value={formData.message} onChange={handleChange} placeholder="Enter your message" />
          </FormControl>
          <Button type="submit" colorScheme="blue">Submit</Button>
        </form>
      </Box>
    </Container>
  );
};

export default ContactForm;