import React from 'react';
import Logo from "../img/two-birds/two_birds_one_stone_logo_blue_TRANSPARENT.png";
import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box bg="gray.800" color="white" py={8}>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        maxW="container.lg"
        mx="auto"
        px={6}
      >
        <Box flex="1">
          <Image src={Logo} alt="Two Birds one stone logo" w="100px" />
          <Text mt={4} color="gray.300">
            Company Location: 97 Alderley Road, Wilmslow, Cheshire
          </Text>
          <Text color="gray.300">Email: info@twobirdsonestone.co.uk</Text>
        </Box>
        <Flex mt={{ base: 4, md: 0 }}>
          <Link mr={4} href="/clients" color="gray.300">
            Clients
          </Link>
          <Link mr={4} href="/candidates" color="gray.300">
            Candidates
          </Link>
          <Link mr={4} href="/contact-us" color="gray.300">
            Contact Us
          </Link>
          <Link mr={4} href="/about-us" color="gray.300">
            About Us
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;