import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Heading, Text } from '@chakra-ui/react';

import jobs from '../data/jobs.json';
import Marketing from '../img/two-birds/marketing.jpg'
import OurJourney from '../components/home/banner';

import ContactForm from '../components/contact-form';

const JobDetailPage = () => {
  const { id } = useParams();

  // Find the job with the matching ID
  const job = jobs.find(job => job.id === parseInt(id));

  if (!job) {
    return <Container maxW="container.lg">Job not found</Container>;
  }

  return (
    <>
      <OurJourney
        heading='Like what you see? Get in touch'
        body="We're passionate about finding the perfect match for candidates and clients"
        image={Marketing}
        imageAlt=''
      />
      <Container maxW="container.lg" mt={8}>
        <Heading as="h1" size="xl" mb={4}>
          {job.title}
        </Heading>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          Salary: {job.salary}
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          Location: {job.location}
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          Contract Type: {job.contractType}
        </Text>
        <Text whiteSpace="pre-line" mb={4}>
          {job.description}</Text>
          <ContactForm />

      </Container>
    </>
  );
};

export default JobDetailPage;