import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text, Flex, Stack } from '@chakra-ui/react';

const CandidatePage = () => {
  return (
    <Box p="6">
      {/* Section 1: Tell us about your journey */}
      <Box mb="8">
        <Heading as="h2" size="lg" mb="4">
          Tell us about your journey
        </Heading>
        <Text mb="4">
          Your achievements drive our achievements. We&apos;re committed to uncovering your career aspirations and empowering you to reach your full potential. Whether you&apos;re a recent graduate, re-entering the workforce, seeking a new career path, or aiming for advancement, we&apos;re eager to learn about your journey.
        </Text>
        <Flex align="center">
        </Flex>
      </Box>

      {/* Section 2: Our Services */}
      <Box mb="8">
        <Heading as="h2" size="lg" mb="4">
          Our Services
        </Heading>
        <Stack spacing="4">
          <ServiceCard title="Job Search Assistance" description="Our extensive network and resources enable us to identify and present you with relevant job opportunities tailored to your skills and preferences." />
          <ServiceCard title="CV Review and Feedback" description="We offer valuable insights and feedback to help you optimize your CV, highlighting your strengths and achievements to stand out to potential employers." />
          <ServiceCard title="Interview Support" description="Elevate your interview performance with our expert coaching. Gain access to tailored documents filled with insider tips, proven techniques, and interview preparation sessions designed to empower you to confidently showcase your skills and leave a lasting impression on hiring managers." />
          <ServiceCard title="Negotiation Support" description="Maximize your offers with our expert guidance. We&apos;ll help you navigate salary discussions and benefits packages, ensuring you receive the recognition and rewards you deserve." />
          <ServiceCard title="Career Advice &amp; Market Insight" description="Whether you&apos;re exploring new career paths or seeking advancement opportunities, our consultants offer valuable advice and market insights to support your career growth." />
        </Stack>
      </Box>

      {/* Section 3: Our Approach */}
      <Box>
        <Heading as="h2" size="lg" mb="4">
          Our Approach
        </Heading>
        <Text mb="4">
          As FMCG specialists, we stand out by prioritizing your satisfaction over fees. Every action and decision is driven by your success. Our team is dedicated to connecting you with the perfect opportunities, empowering you to reach your full career potential. At our core, we value people over metrics.
        </Text>
      </Box>
    </Box>
  );
};

const ServiceCard = ({ title, description }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p="4">
      <Heading as="h3" size="md" mb="2">
        {title}
      </Heading>
      <Text>{description}</Text>
    </Box>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CandidatePage;