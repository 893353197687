import React, { useState } from 'react';
import { Box, Text, VStack, HStack, IconButton, Center } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, StarIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import jobsData from '../data/jobs.json';

const JobsList = () => {
  const [index, setIndex] = useState(0);
  const history = useHistory();

  const handlePrevClick = () => {
    if (index > 0) setIndex(index - 1);
  };

  const handleNextClick = () => {
    if (index < jobsData.length - 1) setIndex(index + 1);
  };

  const handleJobClick = (id, title) => {
    history.push(`/job/${id}/${encodeURIComponent(title)}`);
  };

  return (
    <Box p={10}>
      <Center>
        <Text fontSize="2xl" fontWeight="bold" mb={5}>Featured Jobs</Text>
      </Center>
      {/* Horizontal layout on desktop */}
      <HStack spacing={5} justify="center" display={{ base: 'none', md: 'flex' }}>
        {jobsData.slice(index, index + 3).map((job) => (
          <Box
            key={job.id}
            border="1px solid #E2E8F0"
            borderRadius="md"
            p={4}
            maxW="sm"
            w="100%"
            textAlign="left"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.05)", shadow: "lg" }}
            minHeight="290px" // Set minimum height for each card

          >
            <VStack spacing={4} align="start">
              <Text fontSize="lg" fontWeight="bold">{job.title}</Text>
              <Text>{job.location}</Text>
              <Text>{job.salary}</Text>
              <Text>{job.contractType}</Text>
              <HStack spacing={3}>
                <IconButton icon={<StarIcon />} />
                <IconButton icon={<ArrowForwardIcon />} onClick={() => handleJobClick(job.id, job.title)} />
              </HStack>
            </VStack>
          </Box>
        ))}
      </HStack>

      {/* Vertical layout on mobile */}
      <VStack spacing={5} align="center" display={{ base: 'flex', md: 'none' }}>
        {jobsData.slice(index, index + 3).map((job) => (
          <Box
            key={job.id}
            border="1px solid #E2E8F0"
            borderRadius="md"
            p={4}
            maxW="sm"
            w="100%"
            textAlign="left"
            transition="all 0.3s"
            _hover={{ transform: "scale(1.05)", shadow: "lg" }}
          >
            <VStack spacing={4} align="start">
              <Text fontSize="lg" fontWeight="bold">{job.title}</Text>
              <Text>{job.location}</Text>
              <Text>{job.salary}</Text>
              <Text>{job.contractType}</Text>
              <HStack spacing={3}>
                <IconButton icon={<ArrowForwardIcon />} onClick={() => handleJobClick(job.id, job.title)} />
              </HStack>
            </VStack>
          </Box>
        ))}
      </VStack>

      <Center mt={5}>
        <IconButton
          icon={<ChevronLeftIcon boxSize={6} />}
          onClick={handlePrevClick}
          isDisabled={index === 0}
        />
        <IconButton
          icon={<ChevronRightIcon boxSize={6} />}
          onClick={handleNextClick}
          isDisabled={index >= jobsData.length - 3}
        />
      </Center>
    </Box>
  );
};

export default JobsList;